import React from "react"
import {Col,Row,Card,Button,CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText} from "reactstrap"
import Mobile from "../images/Mobile.png"
import {FaPhoneAlt,FaDiagnoses,FaHandHolding,FaShoppingBag} from "react-icons/fa"

const HowItWorks = () =>{
    return(
        <div className="how-it-works">
            <h2 className="text-center text-white p-3 font-weight-bolder">How it <span className="text-color-secondary">Works?</span></h2>
            <Row className="justify-content-center align-items-center">
                <Col md="3">
                    <Card className="p-4 my-3">
                        <FaPhoneAlt id="how-it-works-icon"/>
                        <CardBody>
                            <CardTitle><h4 className="text-center py-2"><span className="text-color-primary bg-color-primary py-2 px-3 text-white m-2">1</span>Call Us</h4></CardTitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="p-4 my-3">
                        <FaHandHolding id="how-it-works-icon"/>
                        <CardBody>
                            <CardTitle><h4 className="text-center py-2"><span className="text-color-primary bg-color-primary py-2 px-3 text-white m-2">2</span>Collect</h4></CardTitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="p-4 my-3">
                        <FaDiagnoses id="how-it-works-icon"/>
                        <CardBody>
                            <CardTitle><h4 className="text-center py-2"><span className="text-color-primary bg-color-primary py-2 px-3 text-white m-2">3</span>Diagnose</h4></CardTitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="p-4 my-3">
                        <FaShoppingBag id="how-it-works-icon"/>
                        <CardBody>
                            <CardTitle><h4 className="text-center py-2"><span className="text-color-primary bg-color-primary py-2 px-3 text-white m-2">4</span>Deliver</h4></CardTitle>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default HowItWorks
import React from "react"
import {Jumbotron} from "reactstrap"

const FreeOffer = () =>{
    return(      
        <Jumbotron>
            <h3 className="text-center">
                Get a <span className="text-color-primary"><strong>Free Tempered Glass / Mobile Case</strong></span> on Fixing a Mobile<small><sup>*</sup></small>
            </h3>
            <br/>
            <h4 className="text-center">Free <span className="text-color-primary"><strong>Mouse / Wi-Fi Card</strong></span> on Computer Service<small><sup>*</sup></small></h4>
            <small className="p-0 d-flex justify-content-end">*-terms and conditions apply</small>
        </Jumbotron>
    )
}

export default FreeOffer
import React from "react"
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import {FaTag,FaCalendarAlt,FaCarCrash,FaUserCheck} from "react-icons/fa"

const ChooseUs =()=>{
    return (
      <div className="upper-curve pt-5 choose-us">
        <h1 className="text-center font-weight-bolder">
          Why <span className="text-color-primary">Choose Us?</span>
        </h1>
        <br />
        <h4 className="text-center" style={{ lineHeight: "50px" }}>
          You will be getting several Advantages when you allow us fix your
          Gadgets. <br /> A few to mention,
        </h4>
        <Container className="pt-3 mb-4">
          <Row className="justify-content-center">
            <Col>
              <div className="text-center">
                <FaTag size="5rem" color="#527" className="p-3" />
                <br />
                <h5>
                  <strong>Low Price Assured</strong>
                </h5>
                <br />
              </div>
              <p>
                Assured Low price is given for all the products when comparing
                to other Online and Offline Stores.
              </p>
            </Col>
            <Col>
              <div className="text-center">
                <FaCalendarAlt size="5rem" color="#527" className="p-3" />
                <br />
                <h5>
                  <strong>100 Days of Warranty</strong>
                </h5>
                <br />
              </div>
              <p>
                Every Repairs and Refurbished Devices comes with a minimum
                warranty of <strong>100 Days</strong>
              </p>
            </Col>
            <Col>
              <div className="text-center">
                <FaCarCrash size="5rem" color="#527" className="p-3" />
                <br />
                <h5>
                  <strong>Express Pickup & Delivery</strong>
                </h5>
                <br />
              </div>
              <p>
                We will get your device fixed at high pace and also we will take
                care of Pick up and Delivery of your Device.{" "}
              </p>
            </Col>
            <Col>
              <div className="text-center">
                <FaUserCheck size="5rem" color="#527" className="p-3" />
                <br />
                <h5>
                  <strong>Authorized Professionals</strong>
                </h5>
                <br />
              </div>
              <p>
                All Your Device Glitches are handled by certified , Authorized
                and Experienced Professionals
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default ChooseUs
import React from "react";
import {Container,Row,Col,Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button} from "reactstrap"
import Laptop from "../images/Laptop.png"
import Mobile from "../images/Mobile.png"
import Tablet from "../images/Tablet.png"
import Desktop from "../images/Desktop.png"
import MalwareRemoval from "../images/malware.png"
import DataRecovery from "../images/datarecovery.png"
import CCTV from "../images/cctv.png"
import {Link} from "gatsby"
import {FaArrowRight} from "react-icons/fa"
const ServicesOffered = () =>{
    return(
        <div className="our-services">
            <h3 className="text-center p-5 font-weight-bolder">Our <span className="text-color-primary">Services</span></h3>
            <Row className="text-center align-items-center justify-content-center">
                <Col md={3}>
                    <Card className="hover-cta">
                        <CardImg top width="100%" src={Mobile} alt="Mobile Repair" />
                        <Button className="bg-color-primary"><Link className="text-white" to="/#contact">Contact Now</Link></Button>
                        <CardBody>
                            <CardTitle>Mobile Phone Repair</CardTitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="hover-cta">
                        <CardImg top width="100%" src={Tablet} alt="Tablet Repair" />
                            <Button className="bg-color-primary"><Link className="text-white" to="/#contact">Contact Now</Link></Button>
                        <CardBody>
                            <CardTitle>Tablet Repair</CardTitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="hover-cta">
                        <CardImg top width="100%" src={Laptop} alt="Laptop Repair" />
                            <Button className="bg-color-primary"><Link className="text-white" to="/#contact">Contact Now</Link></Button>
                        <CardBody>
                            <CardTitle>Laptop Repair/Upgrade</CardTitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="hover-cta">
                        <CardImg top width="100%" src={Desktop} alt="Desktop Repair" />
                            <Button className="bg-color-primary"><Link className="text-white" to="/#contact">Contact Now</Link></Button>
                        <CardBody>
                            <CardTitle>Desktop Service</CardTitle>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-5 text-center align-items-center justify-content-center">
                <Col md={3}>
                    <Card className="hover-cta">
                        <CardImg top width="100%" src={CCTV} alt="CCTV installation & Service"/>
                        <Button className="bg-color-primary"><Link className="text-white" to="/#contact">Contact Now</Link></Button>
                        <CardBody>
                            <CardTitle>CCTV Installation & Maintenance</CardTitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="hover-cta">
                        <CardImg top width="100%" src={MalwareRemoval} alt="Malware Removal"/>
                        <Button className="bg-color-primary"><Link className="text-white" to="/#contact">Contact Now</Link></Button>
                        <CardBody>
                            <CardTitle>Malware / Virus Clean</CardTitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="hover-cta">
                        <CardImg top width="80%" src={DataRecovery} alt="Data Recovery"/>
                        <Button className="bg-color-primary"><Link className="text-white" to="/#contact">Contact Now</Link></Button>
                        <CardBody>
                            <CardTitle>Data Recovery</CardTitle>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center align-items-center">
                <Button className="cta-button p-3">See More Services <FaArrowRight/></Button>
            </Row>
        </div>
    )
}
export default ServicesOffered  
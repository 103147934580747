import React from "react"
import {Container,Row,Col,Card,CardTitle,CardText,Button,CardBody,CardImg} from "reactstrap"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Rangaraj from "../images/testimonais/Testimonial-Rangaraj.webp"
import Vidhya from "../images/testimonais/Testimonial-Vidhya.png"
const Testimonial = () =>{
    return (
        <div className="pt-5 testimonial-section">
            <Container>
            <h3 className="p-5 text-center font-weight-bold">
                Customer <span className="text-color-primary">Feedbacks</span>
            </h3>
                <Row>

                {/*Rangaraj Begins */}

                    <Col md={6}>
                        <Card className="p-3">
                            <CardBody>
                                <CardTitle>
                                    <h4>Satisfied Customer - Rangaraj</h4></CardTitle>
                                <CardText>Words of Our Satisfied Customer</CardText>
                                <CardText>
                                    <blockquote>
                                        I have Given Samsung ON 6 mobile to Change Display.
                                        Recticart has picked up the mobile from my home itself and also they have returned them back to home itself.
                                        After Service, Mobile phone is so great to Use.
                                        Also the price is comparatively low than any other around.
                                        You Can also try Recticart and become satisfy.
                                    </blockquote>
                                </CardText>
                                <p>Ratings - <span style={{fontSize:'25px'}}>	&#127775;	&#127775;	&#127775;	&#127775;	&#127775; </span> </p>
                            </CardBody>
                            <Row className="px-5 align-items-center">
                                <Col md={7}>
                                    <i>Rangaraj</i> <small>- <i>Saravanampatti, Coimbatore</i></small>
                                </Col>
                                {/*<StaticQuery
                                    query={TestimonialPicsQuery}
                                    render={data => {
                                    return (
                                        <Img
                                            fluid={data.allFile.edges.node.childImageSharp.fluid}
                                        />
                                    )}}
                                    />*/}
                                <Col md={5}>
                                    <CardImg
                                    bottom
                                    width="100%"
                                    src={Rangaraj}
                                    alt="Customer Testimonial"
                                    className="img img-responsive w-50"
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="h-100">
                            <iframe
                                title="youtube-testimonial"
                                height="100%"
                                src="https://www.youtube.com/embed/bKlhTR1dbwI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen
                            ></iframe>
                        </Card>
                    </Col>
                </Row>

                {/*Rangaraj Ends */}

                {/*Vidhya Begins */}

                <Row className="py-3">
                    <Col md={6}>
                        <Card className="h-100">
                            
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="p-3">
                            <CardBody>
                                <CardTitle>
                                    <h4>Satisfied Customer - Vidhya</h4></CardTitle>
                                <CardText>Words from Our Satisfied Customer</CardText>
                                <CardText>
                                    <blockquote>
                                        Great team and constantly helped me to get my mobile done in this pandamic situation too. That was a great thing..
                                        Your services are all good and customer friendly keep going.
                                    </blockquote>
                                </CardText>
                                <p>Ratings - <span style={{fontSize:'25px'}}>	&#127775;	&#127775;	&#127775;	&#127775;	&#127775; </span> </p>
                            </CardBody>
                            <Row className="px-5 align-items-center">
                                <Col md={7}>
                                    <i>Vidhya</i> <small>- <i>Saibaba Colony, Coimbatore</i></small>
                                </Col>
                                {/*<StaticQuery
                                    query={TestimonialPicsQuery}
                                    render={data => {
                                    return (
                                        <Img
                                            fluid={data.allFile.edges.node.childImageSharp.fluid}
                                        />
                                    )}}
                                    />*/}
                                <Col md={5}>
                                    <CardImg
                                    bottom
                                    width="100%"
                                    src={Vidhya}
                                    alt="Customer Testimonial"
                                    className="img img-responsive w-50"
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {/*Vidhya Ends */}


            </Container>
        </div>
    )
}


const TestimonialPicsQuery = graphql`
    query TestimonialPicsQuery {
    allFile(filter: {relativePath: {regex: "/testimonais/"}}) {
        edges {
        node {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    }
    }
`

export default Testimonial
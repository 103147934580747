import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';
import SurpriseGifts from "../images/surprisegifts.png"
import Days100Warranty from "../images/100-days-warranty.png"
import FixingEasy from "../images/fixingeasy.png"

const items = [
  {
    src: Days100Warranty,
    altText: 'Slide 1',
    // caption: 'Slide 1',
    // header: 'Slide 1 Header',
    key: '1'
  },
  {
    src: FixingEasy,
    altText: 'Slide 2',
    // caption: 'Slide 2',
    // header: 'Slide 2 Header',
    key: '2'
  },
  {
    src: SurpriseGifts,
    altText: 'Slide 3',
    // caption: 'Slide 3',
    // header: 'Slide 3 Header',
    key: '3'
  }
];

const Carouseleg = () => <UncontrolledCarousel items={items} />;

// const CarouselImageQuery = graphql`
//   CarouselImageQuery {

//   }
// `

export default Carouseleg;
import React, { Component } from "react";
import ReactTextTransition, { presets } from "./text-transition";
import { Row,Col } from "reactstrap";
import QRContact from "./QRContact"

const randomNumber = () => Math.floor(Math.random() * 9999999999 + 10000000000);

const texts = ['Mobile Phone','Laptop','Tablet','Desktop','CCTV Camera','RAM Upgrade','SSD Upgrade','Hard Disk Upgrade'];


class WhatWeDo extends Component {
    state = {
        number: randomNumber(),
        textIndex: 0,
        textFastIndex: 0,
        paragraphIndex: 0
    };

    componentDidMount() {
        setInterval(() => {
        this.setState({
            number: randomNumber(),
            textIndex: this.state.textIndex + 1,
            paragraphIndex: this.state.paragraphIndex + 1
        });
        }, 4000);
        setInterval(() => {
        this.setState({
            textFastIndex: this.state.textFastIndex + 1
        });
        }, 150);
    }

    render() {
        let { getQuote } = this.state
        return (
        <React.Fragment>
            <section className="p-5 text-center area justify-content-center align-items-center">
                {/*<h1>What <span className="text-color-primary font-weight-bolder">We Do?</span></h1>*/}
                <Row>
                    <Col>
                        <section>
                        <h1 className="p-3" style={{wordWrap:'break-word'}}>
                            We Fix Your Damaged
                            <ReactTextTransition
                                text={texts[this.state.textIndex % texts.length]}
                                spring={presets.gentle}
                                inline
                                className="text-color-primary font-weight-bolder ml-2 text-center"
                            />
                            s.
                        </h1>
                        <h2 style={{fontSize:'25px' , color:'#527'}} className="font-weight-bold">All at Your Door Step</h2>
                        <br/>
                            <button className="text-dark text-center justify-content-center cta-button">
                            <a className="text-white" href="#contact">
                            Contact Now!
                            </a>
                            </button>
                        </section>
                    </Col>
                    <Col>
                        <QRContact/>
                    </Col>
                </Row>
            </section>
        </React.Fragment>
        );
    }
}

export default WhatWeDo;

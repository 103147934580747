import React, {Component} from "react"
import {FaWhatsapp} from "react-icons/fa"
import DiscountQRcode from "../images/HeyRecticart.svg"
import {Input,InputGroup,Button,InputGroupAddon} from "reactstrap";


class QRContact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            getQuote: "",
        }
    }
    

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
        [name]: value,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let { getQuote } = this.state
        let data = { getQuote }
        if (data.getQuote.length > 0){
            window.open('https://api.whatsapp.com/send/?phone=919626330185&text='+getQuote)
        }else{
            window.open('https://api.whatsapp.com/send/?phone=919626330185&text=Hello+Recticart')
        }
    }

    render() {
        let { getQuote } = this.state
        return(
        <>
            <section>
                <img src={DiscountQRcode} className="img img-responsive mx-auto py-3"/>
                <p className="text-color-primary font-weight-bolder">Scan the QRCode & get a Discount Coupon</p>
            </section>
            <section className="hero-buttons">
                <InputGroup className="mx-auto">
                    <Input placeholder="Enter Service Requirement" onChange={this.handleInputChange} bsSize="lg" name="getQuote"/>
                    <InputGroupAddon addonType="append">
                    <Button 
                        color="success m-0"
                        name="submit"
                        type="submit"
                        onClick={this.handleSubmit}
                        >
                        Get Quote<FaWhatsapp size={25} style={{paddingLeft: '5px'}}/>
                    </Button>
                    </InputGroupAddon>
                </InputGroup>
            </section>
        </>
        )
    }
}

export default QRContact;
import React, { Component } from "react"
import axios from "axios"
import endpoints from "../utils/endpoints"
import Popup from "./modal"
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap"
import {
FaFacebookMessenger,
FaInstagram,
FaTelegramPlane,
} from "react-icons/fa"

export default class ContactForm extends Component {
constructor(props) {
    super(props)
    this.state = {
    name: "",
    email: "",
    model: "",
    phone: "",
    area: "",
    coupon: "",
    message: "",
    showModal: false,
    valid: false,
    error: false,
    }
}

change = e => {
    let whoIsChecked = Object.assign({}, this.state.whoIsChecked);
    whoIsChecked.allowDestroyAll = e.target.value
    this.setState({ whoIsChecked })
    console.log(whoIsChecked.allowDestroyAll)
}

handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
    [name]: value,
    })
}

handleSubmit = e => {
    this.setState({ loading: true })
    let model = this.state.whoIsChecked.allowDestroyAll;
    // console.log("model is", model)
    let { name, email, phone, address, area, coupon, message } = this.state
    let data = { name, email, phone, address, area, model, coupon, message }
    axios
    .post(endpoints.contactform, JSON.stringify(data))
    .then(data => {
        this.handleSuccess(data)
    })
    .catch(({ error, response }) => {
        this.handleError(error, response)
    })
    e.preventDefault()
}

handleSuccess = data => {
    console.log(data)
    this.setState({
    name: "",
    email: "",
    message: "",
    model: "",
    phone: "",
    area: "",
    coupon: "",
    showModal: true,
    valid: false,
    alert: "Thanks for Contacting Us.We Will Get You Back Soon.",
    })
}

handleError = (error, data) => {
    console.log(data)
    this.setState({
    showModal: true,
    alert: `${data.data.errorMessage}`,
    valid: false,
    loading: false,
    })
}

closeModal = () => {
    this.setState({ showModal: false })
}

onConfirm = () => {
    this.setState({ valid: true })
}

render() {
    let { name, message, phone, model, area, coupon, alert } = this.state
    return (
    <div>
        <div>
        <h3 className="text-center text-dark p-5 h-50 font-weight-bold">
            Contact Us <span className="text-color-primary">Now</span>
        </h3>
        </div>
        <Row className="d-flex justify-content-center">
        <Col md={10}>
            <Form name="contact Form" onSubmit={this.handleSubmit}>
            <Row form>
                <Col sm={2}>
                <p>
                    <strong>Name</strong>
                </p>
                </Col>
                <Col sm={10}>
                <FormGroup>
                    <Input
                    name="name"
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    onChange={this.handleInputChange}
                    autoComplete="off"
                    required
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={2}>
                <p>
                    <strong>Contact Number</strong>
                </p>
                </Col>
                <Col md={10}>
                <FormGroup>
                    <Input
                    name="phone"
                    maxLength="10"
                    minLength="10"
                    type="text"
                    inputmode="numeric"
                    placeholder="Contact Number"
                    value={phone}
                    onChange={this.handleInputChange}
                    autoComplete="off"
                    required
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={2}>
                <p>
                    <strong>Locality / Area</strong>
                </p>
                </Col>
                <Col md={10}>
                <FormGroup>
                    <Input
                    name="area"
                    type="text"
                    placeholder="Location"
                    value={area}
                    onChange={this.handleInputChange}
                    autoComplete="off"
                    required
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={2}>
                <p>
                    <strong>Device Type</strong>
                </p>
                </Col>
                <Col md={10}>
                <FormGroup>
                    <Input
                    type="select"
                    name="model"
                    id="exampleSelect"
                    onChange={this.change}
                    placeholder="Device Type"
                    required="required"
                    >
                    <option value="">Select a Device</option>
                    <option>Mobile</option>
                    <option>Tablet</option>
                    <option>Laptop</option>
                    <option>Desktop</option>
                    <option>CCTV Camera</option>
                    </Input>
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={2}>
                <p>
                    <strong>Repair Description</strong>
                </p>
                </Col>
                <Col md={10}>
                <FormGroup>
                    <Input
                    name="message"
                    type="textarea"
                    placeholder="Short Description on your Device Issue"
                    value={message}
                    onChange={this.handleInputChange}
                    autoComplete="off"
                    required
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={2}>
                <p>
                    <strong>Got A Coupon?</strong>
                </p>
                </Col>
                <Col md={10}>
                <FormGroup>
                    <Input
                    name="coupon"
                    type="text"
                    placeholder="Got A Coupon? Redeem Here."
                    value={coupon}
                    onChange={this.handleInputChange}
                    autoComplete="off"
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col md={12}>
                <Button
                    className="text-center px-5 bg-color-primary btn-block"
                    name="submit"
                    type="submit"
                    value="Send It"
                >
                    Send
                </Button>
                </Col>
            </Row>
            <Popup
                visible={this.state.showModal}
                onClickAway={() => this.closeModal()}
            >
                <p>
                <div dangerouslySetInnerHTML={{ __html: `${alert}` }} />
                </p>
            </Popup>
            </Form>
        </Col>
        </Row>
        <Row className="d-flex justify-content-center">
        <Col md={6} className="mt-5">
            <h4 className="text-center font-weight-bold">
            Contact us via{" "}
            <span className="text-color-primary">Social Media</span>
            </h4>
            <div className="d-flex justify-content-center mt-3">
            <Col size={3}>
                <a
                href="https://m.me/recticart"
                rel="noopener nofollow"
                target="_blank"
                >
                <FaFacebookMessenger
                    size={70}
                    color="#552277"
                    className="px-2"
                />
                <p className="text-color-primary">Messenger</p>
                </a>
            </Col>
            <Col size={3}>
                <a
                href="https://www.instagram.com/recticart/"
                rel="noopener nofollow"
                target="_blank"
                >
                <FaInstagram size={70} color="#552277" className="px-2" />
                <p className="text-color-primary">Instagram</p>
                </a>
            </Col>
            <Col size={3}>
                <FaTelegramPlane size={70} color="#552277" className="px-2" />
                <p className="text-color-primary">Telegram</p>
            </Col>
            </div>
        </Col>
        </Row>
    </div>
    )
}
}
import React, { Component } from 'react'
import Modal from 'react-awesome-modal'
import { FaTimesCircle, FaWeight } from 'react-icons/fa'

export default class ResponsePopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  openModal() {
    this.setState({
      visible: true,
    })
  }

  closeModal() {
    this.setState({
      visible: false,
    })
  }

  render() {
    return (
        <Modal visible={this.props.visible} effect="fadeInUp">
            <div className="d-flex justify-content-end m-3">
                <FaTimesCircle onClick={this.props.onClickAway} style={{float:'right',cursor:'pointer'}}/>
            </div>
            <div className="text-center px-5 pb-5" style={{fontWeight:'bold'}}>
                {this.props.children}
            </div>
        </Modal>
    )
  }
}
